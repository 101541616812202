import VoteComponent from '../Components/Server/Vote';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function () {
  return (
    <div className="w-full h-full min-h-screen bg-zinc-100 ">
      <Header/>
      <VoteComponent/>
      <Footer/>
    </div>
  );
};