import { MdDateRange, RiShieldUserFill, FaUser, FaCrown } from '../../Utils/Icons';
import Toast from 'react-hot-toast';
import Twemoji  from 'react-twemoji';
import Tippy from '..//Tippy'

function Comments({ comments }) {
  const stars = {};

  comments.map((data, index) => {
    for (let i = 0; i < data.review; i++) {
      if (!stars[index]) stars[index] = [];
      stars[index].push(<Twemoji className="w-[16px] h-[16px]">⭐</Twemoji>);
    };
  });

  return (<>
      <div className="text-gray-600 text-xs w-full mt-4 h-max border-[2px] bg-zinc-200 p-4 rounded-lg gap-y-4 flex flex-col">
        {comments.length > 0 ? comments.map((data, index) => (
          <div key={index} className="flex flex-row gap-4 justify-start">
            <div className="flex-row justify-center w-max flex gap-x-3 select-none">
              <img className="rounded-full w-[48px] h-[48px] object-cover" src={data.img} alt={`${data.tag} kullanıcısının profil resmi`} />
              <div className="flex flex-col gap-1">
                <div className="flex text-gray-700 text-sm font-bold flex-row w-full sm:w-max h-[25%] mb-2 sm:mb-0 sm:h-[50%] items-center sm:justify-center gap-x-2 flex-wrap">
                  {data.tag}
                  <div className="w-[1px] h-full flex items-center justify-center">
                    <span className="w-[1px] h-[80%] bg-zinc-300">&thinsp;</span>
                  </div>
                  <Tippy title="Gönderilme tarihi" className="flex-row gap-1 !flex items-center text-gray-500 font-medium text-xs">
                    <MdDateRange size={16} />
                    {data.date}
                  </Tippy>
                  <div className="sm:flex w-[1px] h-full hidden items-center justify-center">
                    <span className="w-[1px] h-[80%] bg-zinc-300">&thinsp;</span>
                  </div>
                  <Tippy title={`Değerlendirme: ${data.review}/5`} className='flex-row gap-1 !flex items-center'>
                    {stars[index].map(element => element)}
                  </Tippy>
                </div>
                <div className="mt-2 sm:mt-0 select-text flex text-gray-500 text-xs font-medium flex-row w-full h-[50%] items-centerr">
                  {data.comment}
                </div>
              </div>
            </div>
          </div>
        )) : (
          <div className="flex flex-col items-center justify-start">
            <div className="text-gray-500 text-xs font-medium">
              Henüz yorum yok.
            </div>
          </div>
        )}
      </div>
    </>)
};

function Info({ data }) {
  function copyId(color) {
    if (color === '#000000') return Toast.error('Renk bulunamadı.');
    navigator.clipboard.writeText(color);
    Toast.success('Renk panoya kopyalandı.');
  };

  return (<>
      <div className="text-gray-600 text-xs w-full mt-4 h-max border-[2px] bg-zinc-200 p-4 rounded-lg">
        <h1 className="text-lg text-gray-800 font-bold">Açıklama</h1>
        <div className='w-full h-[1px] bg-zinc-300 mb-2 mt-0'>&thinsp;</div>
        <span>{data.description}</span>

        {data.owner?.tag && (
          <>
            <h1 className="mt-4 text-lg text-gray-800 font-bold">Sahip</h1>
            <div className='w-full h-[1px] bg-zinc-300 mb-2 mt-0'>&thinsp;</div>
            <div className="flex flex-row items-center w-max justify-center gap-x-1">
              <img src={data.owner.img} alt={`${data.owner.tag} kullanıcısının profil resmi`} className="w-[20px] h-[20px] rounded-full object-cover"></img>
              <span className='text-[16px] font-semibold select-none'>{data.owner.tag}</span>
              <FaCrown className='ml-1 text-yellow-600' size={18}/>
            </div>
          </>
        )}

        <h1 className="text-lg mt-4 text-gray-800 font-bold">Ses</h1>
        <div className='w-full h-[1px] bg-zinc-300 mb-2 mt-0'>&thinsp;</div>
        <span>
          {data.average ? (
            <>
              Son 24 saatlik ortalama ses aktifliği: {data.average}
            </>
          ) : (
            <>
              Son 24 saatlik ortalama ses aktifliği hesaplanamadı.
            </>
          )}
        </span>

        <h1 className="text-lg mt-4 text-gray-800 font-bold">Roller ({data.roles?.length})</h1>
        <div className='w-full h-[1px] bg-zinc-300 mb-2 mt-0'>&thinsp;</div>
        <div>

          <div className="flex items-center justify-start text-gray-600 text-[16px] w-full h-max bg-zinc-200 py-1 text-xs rounded-lg">
            <div className='flex w-full flex-col gap-y-1'>
              {data.roles?.map((item, index) => (
                <div onClick={() => copyId(item.color)} key={index} className='flex w-full rounded-lg hover:bg-zinc-300 px-2 py-1 cursor-pointer gap-2 hover:!text-gray-700'>
                  <RiShieldUserFill size={20} color={item.color === '#000000' ? 'rgb(75 85 99)' : item.color}/>
                  <span className='w-max'>{item.name}</span>
                  <div className='ml-auto flex flex-row items-center gap-1'>
                    <span>{item.members}</span>
                    <FaUser size={12}/>
                  </div>
                </div>
              ))}
              {data.roles?.length <= 0 && 'Hiç rol yok.'}
            </div>
          </div>

        </div>
      </div>
    </>)
};


export { Comments, Info };