import { GrFormNextLink } from '../Utils/Icons'; 
import { useNavigate, useLocation } from 'react-router-dom';

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function() {
  const navigate = useNavigate();
  const location = useLocation();

  window.scrollTo(0, 0);

  return (
    <div className="w-full min-h-[63vh] flex items-center justify-center">
      <div className="w-full max-w-screen-xl pb-4 pt-[5rem] sm:pt-[23vh] py-8 mx-auto text-center">
        <h1 className="text-md sm:text-3xl font-bold text-black">
          Sayfa bulunamadı
        </h1>
        
        <p className="text-gray-800 text-[10px] sm:text-sm mt-1">
          Aradığınız sayfa ({location.pathname}) bulunamadı.
        </p>

        <button onClick={() => navigate(-1)} className='white flex mt-2 sm:mt-4 mx-auto items-center justify-center gap-1 transition-all active:scale-90 hover:-translate-y-1 text-[10px] sm:text-[14px] bg-black text-white w-max py-2 px-6 rounded-2xl font-semibold tracking-wide'>
          Geri dön
          <GrFormNextLink size={18}/>  
        </button>
      </div>
    </div>
  );
};