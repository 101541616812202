import logo from '../Images/logo.png'; 
import Contacts from '../Utils/Contacts'
import { NavLink } from 'react-router-dom';
import Tippy from './Tippy'

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function() {
  
  window.addEventListener('beforeinstallprompt', event => {
    const pwaButton = document.getElementById('pwa');
    pwaButton.classList.add('!block');
    pwaButton.onclick = () => event.prompt();
  }); 
  
  return (
    <div className="pt-[80px] px-[30px] xl:px-[80px] pb-[15px] sm:pb-[40px] w-full h-max flex flex-col items-center justify-center transition-all">
      <div className="h-max sm:h-[200px] flex flex-col sm:flex-row w-full xl:w-[80%] 2xl:w-[60%] relative">
        <div className='flex flex-col w-full justify-center items-center sm:justify-start sm:items-start'>
          <div className="flex flex-row gap-x-4 items-center w-max">
            <img className="w-[40px] h-auto rounded-full" src={logo} alt='Vocal'/>
            <span className='font-bold text-black text-2xl'>Vocal</span>
          </div>
          <div className='mt-[24px] font-medium text-sm text-gray-800 max-w-[100%] text-center sm:text-left sm:max-w-[250px] md:max-w-[360px]'>
            Vocal en çok kullanılan sunucuları sıralamak için kullanılır.
          </div>
        </div>
        <div className='flex flex-row mt-10 sm:mt-0 w-full sm:w-max items-start justify-center gap-10 ml-0 '>
          <div className='flex flex-col gap-2 text-xs'>
            <h1 className='font-bold mb-[15px] text-md'>Website</h1>
            <NavLink to='/' className="hover:underline">Anasayfa</NavLink>
            <a href="https://invite.vocal.tk" className="hover:underline">Davet et</a>
            <span id="pwa" className='hidden cursor-pointer hover:underline w-[130px]'>Uygulamamızı yükle</span>
          </div>
          <div className='flex flex-col gap-2 text-xs sm:hidden'>
            <h1 className='font-bold mb-[15px] text-md'>Bize ulaş</h1>
            {Contacts.map((item, index) => (
              <span key={index} className='flex items-center gap-1 hover:underline'>
                {item.icon}
                <a href={item.to} className="truncate">{item.name}</a>
              </span> 
            ))}
          </div>
        </div>
        <div className='flex-col gap-2 text-xs mx-auto flex-1 items-end sm:flex hidden ml-10'>
          <div className='w-full flex flex-col gap-y-2'>
            <h1 className='font-bold mb-[15px] text-md'>Bize ulaş</h1>
            {Contacts.map((item, index) => (
              <span key={index} className='cursor-pointer flex items-center gap-1 hover:underline'>
                {item.icon}
                <a href={item.to} className="truncate">{item.name}</a>
              </span> 
            ))}
          </div>
        </div>

        <span className='w-full h-[1px] bg-zinc-300 absolute -bottom-5 sm:bottom-10'>&thinsp;</span>
      </div>

      <div className="mt-10 sm:mt-0 h-[30px] flex flex-row w-full xl:w-[80%] 2xl:w-[60%]">
        <span className='text-[14px] text-gray-600 w-full h-full items-center justify-center flex-row flex'>
          <span>© 2022, Tüm hakları saklıdır.</span>
          <div className='flex flex-row gap-6 flex-1 mx-auto justify-end select-none'>
            <Tippy title="Her an bir hata ile karşılaşabilirsiniz.">
              <span className='bg-gray-200 py-1 px-3 rounded-2xl'>BETA</span>
            </Tippy>
          </div>
        </span>
      </div>
    </div>
  );
};