import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function() {

  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get('token');

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (!token) return navigate('/');

    async function verify() {
      setLoading(true);
      const res = await (await fetch(`https://api.vocal.tk/verify?token=` + token, { method: 'POST' })).json();
      setLoading(false);
      setResponse(res);  
    };

    verify();
  }, [token]);
  
  return (
    <>
      <div className='w-full h-full min-h-[63.6vh] flex items-center justify-center'>
        {loading ? (
          <div className='flex items-center gap-1 white'>
            <Oval height={15} width={15} color="rgb(113 113 122)" visible={true} ariaLabel='oval-loading' secondaryColor="rgb(63 63 70)" strokeWidth={6} strokeWidthSecondary={6}/>
            <span>Lütfen bekleyin.</span>
          </div>
        ) : (
          <div className='flex items-center justify-center w-max h-max gap-1 flex-col'>
            <h1 className='text-black text-2xl font-bold'>{response?.title}</h1>
            <p className='text-gray-700 text-sm font-medium'>{response?.message}</p>
          </div>
        )}
      </div>
      
    </>
  );
};