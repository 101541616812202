import Helmet from 'react-helmet';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import Tippy from '../Tippy'
import { Info, Comments } from './Sections';
import { AiFillFlag, MdDateRange, MdKeyboardVoice, IoMdThumbsUp, FaUser } from '../../Utils/Icons';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import { Toaster } from 'react-hot-toast';

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function() {
  
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeSectionTitle, setActiveSectionTitle] = useState('Bilgi');
  const [activeSectionElement, setActiveSectionElement] = useState(<Info/>);
  
  useEffect(() => {
    const elements = {
      'Bilgi': <Info data={data}/>,
      'Yorumlar': <Comments comments={data.comments}/>,
    };

    setActiveSectionElement(elements[activeSectionTitle]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSectionTitle]);

  useEffect(() => {
    setLoading(true);
    const [, , id] = location.pathname.split('/');
    if (!id) return;

    async function getData() {
      const response = await fetch('https://api.vocal.tk/servers/' + id).catch(() => {});
      setLoading(false);
      if (response?.status === 200) {
        const json = await response.json();
        setData(json);
        setActiveSectionElement(<Info data={json}/>);
      } else return navigate('/');
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  function Seperator() {
    return (
      <div className='h-full w-[1px] bg-zinc-300'>&thinsp;</div>
    );
  };

  function Loading() {
    return (
      <div className='flex items-center gap-1 w-full min-h-screen justify-center'>
        <Oval height={15} width={15} color="rgb(113 113 122)" visible={true} ariaLabel='oval-loading' secondaryColor="rgb(63 63 70)" strokeWidth={6} strokeWidthSecondary={6}/>
        <span>Yükleniyor..</span>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Vocal</title>
      </Helmet>
      
      {loading ? <Loading/> : (<>
        <Helmet>
          <title>{`Vocal / ${data.name}`}</title>
          <meta property='description' content={data.description}/>
          {data.banner && <meta property='og:image' content={data.banner}/>}

          <meta property='og:title' content={`Vocal / ${data.name}`}/>
          <meta property='og:description' content={data.description}/>
          {data.banner && <meta property='og:image' content={data.banner}/>}
          <meta property='og:url' content={`https://vocal.tk/servers/${location.pathname.split('/')[2]}`}/>
        </Helmet>

        <div><Toaster position='right-bottom'/></div>
          <div className='flex w-full sm:w-[80%] max-w-[1400px] min-h-screen px-26 pb-6 flex-col'>
            <div className='flex h-[250px] justify-center relative'>
              {data.banner ? <img className="sm:rounded-b-lg w-full object-cover" src={data.banner} alt={`${data.name} sunucusunun afişi`}></img> : <div className="rounded-lg w-full bg-zinc-200"></div>}
              <div className={(!data.banner ? 'bg-zinc-300' : '') + " absolute bottom-[0.5rem] right-2 sm:left-[9.5rem] shadow-md text-white py-1 px-3 backdrop-blur-xl rounded-xl w-max select-none text-xs"}>{data.category}</div>
              <img className="absolute -bottom-14 sm:-bottom-16 left-5 sm:left-10 rounded-full w-[100px] sm:w-[126px] h-auto object-cover border-zinc-100 border-[10px] bg-zinc-100" src={data.img} alt={`${data.name} sunucusunun ikonu`}></img>
              <div className="absolute -bottom-10 left-0 sm:left-40 w-full h-max">
                <h1 className="text-sm ml-[7rem] sm:ml-0 mt-2 sm:text-xl font-bold flex gap-2 items-center">
                  {data.name}
                  <div className="flex gap-1 justify-center items-center w-max h-max">
                    {data.badges?.map((item, index) => (
                      <div key={index} className='w-[20px] h-[20px]'>
                        <Tippy title={item.name}>
                          <img className="w-full h-full object-cover" src={item.img} alt={item.name}></img>
                        </Tippy>
                      </div>
                    ))}
                  </div>
                </h1>
                <div className='-bottom-[50px] flex-wrap ml-[7rem] -mb-[20px] sm:mb-0 sm:ml-0 -left-[100px] sm:-left-[177px] lg:left-0 lg:-bottom-7 absolute max-w-[800px] mt-1 text-[14px] text-gray-600 text-medium flex flex-row gap-2 items-center '>
                  <Tippy title='Oy'>
                    <div className='w-full flex flex-row gap-1 items-center select-none'>
                      <IoMdThumbsUp className="w-[14px]" size={16}/>
                      {data.votes}
                    </div>
                  </Tippy>
                  <Tippy title='Ses'>
                    <div className='w-full flex flex-row gap-1 items-center select-none'>
                      <MdKeyboardVoice className="w-[14px]" size={16}/>
                      {data.voices}
                    </div>
                  </Tippy>
                  <Tippy title='Oluşturulma tarihi'>
                    <div className='w-full flex flex-row gap-1 items-center select-none'>
                      <MdDateRange className="w-[14px]" size={16}/>
                      {data.createdAt}
                    </div>
                  </Tippy>
                  <Seperator/>
                  <Tippy title='Çevrimiçi'>
                    <div className='w-full flex flex-row gap-1.5 items-center select-none'>
                      <FaUser size={12} className='text-green-600 w-[12px]'/>
                      {data.onlines}
                    </div>
                  </Tippy>

                  <Tippy title='Toplam üye'>
                    <div className='w-full flex flex-row gap-1.5 items-center select-none'>
                      <FaUser size={12} className='text-zinc-400 w-[12px]'/>
                      {data.members}
                    </div>
                  </Tippy>
                  <Seperator/>
                  {data.invite && (
                    <a href={data.invite} className='white flex flex-row gap-1 items-center select-none w-max bg-blue-500 py-0.5 sm:py-1 px-2 sm:px-3 rounded-xl text-white cursor-pointer hover:bg-blue-600'>
                      Sunucuya katıl
                    </a>
                  )}
                  <a href="https://discord.vocal.tk" className='white flex flex-row gap-1 items-center select-none w-max bg-red-500 py-0.5 sm:py-1 px-2 sm:px-3 rounded-xl text-white cursor-pointer hover:bg-red-600'>
                    <AiFillFlag className='hidden sm:block' size={12}/>
                    Bildir
                  </a>
                </div>
              </div>
            </div>

            <div className='w-full h-full pt-[8rem] sm:pt-[5rem] px-4 md:px-0'>
              <div className="w-full h-max flex flex-row items-center gap-x-2">
                <div className={(activeSectionTitle === 'Bilgi' ? '!border-zinc-300 !cursor-default border-b-[4px]' : '') + ` select-none bg-zinc-200 px-3 py-1 md:text-[16px] text-[13px] font-semibold rounded-xl cursor-pointer`} onClick={() => setActiveSectionTitle('Bilgi')}>Bilgi</div>
                <div className={(activeSectionTitle === 'Yorumlar' ? '!border-zinc-300 !cursor-default border-b-[4px]' : '') + ` select-none bg-zinc-200 px-3 py-1 md:text-[16px] text-[13px] font-semibold rounded-xl cursor-pointer`} onClick={() => setActiveSectionTitle('Yorumlar')}>Yorumlar</div>
              </div>

              <div className='flex flex-row w-full h-max'>
                {activeSectionElement}
              </div>
            </div>
          </div>
      </>)}
    </>
  );
};