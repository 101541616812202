import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const darkMode = window.localStorage.getItem('darkMode');
if (darkMode) document.documentElement.classList.add('dark');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);