import { GrTwitter, GrInstagram, GrMail, FaDiscord } from '../Utils/Icons';

const Contacts = [
  {
    name: 'Twitter',
    icon: <GrTwitter size={14}/>,
    to: 'https://twitter.com/vocaldotk'
  },
  {
    name: 'Instagram',
    icon: <GrInstagram size={14}/>,
    to: 'https://instagram.com/vocaldotk'
  },
  {
    name: 'Discord',
    icon: <FaDiscord size={14}/>,
    to: 'https://discord.vocal.tk'
  },
  {
    name: 'Mail',
    icon: <GrMail size={14}/>,
    to: 'mailto:skyhancloud@gmail.com'
  }
];

export default Contacts;