import './index.css';
import Home from './Pages/Home';
import Page404 from './Pages/404';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import 'react-tippy/dist/tippy.css'
import Header from './Components/Header';
import Server from  './Pages/Server'
import Vote from  './Pages/Vote'

function App() {
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/servers/:id" exact element={<Server/>}/>
        <Route path="/servers/:id/vote" exact element={<Vote/>}/>
        <Route path="*" element={<Page404/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;