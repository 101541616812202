import ServerComponent from '../Components/Server/index';
import Footer from '../Components/Footer';

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function () {

  return (
    <div className="w-full h-full min-h-screen bg-zinc-100 items-center justify-center flex flex-col">
      <ServerComponent/>
      <Footer/>
    </div>
  );
};