import land from '../Images/land.svg';
import { GrFormNextLink } from '../Utils/Icons';

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function() {

  return (
    <div className="min-h-[100vh] h-full pt-44 px-2 sm:px-10 lg:px-15 2xl:px-28 flex flex-row relative">

      <div className="w-full justify-center lg:h-full flex flex-row items-center">
        <div className="w-full lg:w-[50%] min-h-full flex text-center lg:text-left flex-col justify-center items-center lg:items-baseline lg:justify-start relative lg:left-20 gap-y-3">
          <h1 className='text-[36px] sm:text-[52px] font-bold max-w-full lg:max-w-[550px] leading-[1.10] tracking-wider'>
            <span className='text-blue-500'>En hızlı</span>, en kaliteli sunucu sıralaması
          </h1>
          <h2 className='text-[14px] sm:text-[18px] sm:max-w-[720px] font-semibold leading-2 sm:leading-6 text-gray-800 tracking-wide text-opacity-80'>
            Vocal, en çok kullanılan sunucuları sıralamak için kullanılır.<br></br>
            Hadi sende sunucunu <a className='text-black hover:underline' href="https://invite.vocal.tk">yarışa sok</a>!
          </h2>
          <a href="#servers" className='white flex items-center justify-center gap-1 transition-all active:scale-90 hover:-translate-y-1 mt-2 text-[16px] sm:text-[20px] bg-black text-white w-max py-2 px-6 rounded-2xl font-semibold tracking-wide'>
            Sunucular
            <GrFormNextLink className='rotate-90'/>  
          </a>

          <div className='flex flex-col gap-y-1 justify-center relative top-[14px]'>
            <p className='text-zinc-400 text-[6px] sm:text-[10px] font-medium max-w-[400px] select-none'>* Vocal şu anda beta sürümünüdedir. Kullanırken bir hata ile karşılaşabilirsiniz.</p>
          </div>
        </div>
        
        <div className="hidden lg:flex w-[50%] h-full flex-col items-center justify-end">
          <img className="w-[700px] h-auto relative right-[40px]" src={land} alt=""/>
        </div>
      </div>
    </div>
  );
};