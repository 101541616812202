import Header from '../Components/Header';
import Helmet from 'react-helmet';
import Page404Component from '../Components/404';
import Footer from '../Components/Footer';

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function () {

  return (
    <div className="w-full h-full min-h-screen bg-zinc-100">
      <Helmet>
        <title>Sayfa bulunamadı</title>
      </Helmet>

      <Header/>
      <Page404Component/>
      <Footer/>
    </div>
  );
};