import Logo from '../Images/logo.png';

import { useEffect, useState } from 'react';
import { Oval } from  'react-loader-spinner'
import { NavLink } from 'react-router-dom';
import { FaDiscord, BiSearchAlt2, AiFillHome } from '../Utils/Icons';

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function() {

  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setSearching(true);
      const data = await fetch('https://api.vocal.tk/search?q=' + encodeURIComponent(search)).catch(() => {});
      setSearching(false);
      if (data) {
        const json = await data.json();
        setSearchResults(json);
      } else setSearchResults([]);
    };

    if (document.activeElement.tagName === 'INPUT') fetchData();
    setShowModal(true);
  }, [search]);
 
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.addEventListener('click', event => {
      const safe = [document.getElementById('search-input'), document.getElementById('search-modal'), ...document.getElementById('search-input').getElementsByTagName('*')];
      if (!safe.includes(event.target)) setShowModal(false);
      else setShowModal(true);
    });
  }, []);

  function Loading() {
    return (
      <div className='flex items-center gap-1 white'>
        <Oval height={15} width={15} color="rgb(113 113 122)" visible={true} ariaLabel='oval-loading' secondaryColor="rgb(63 63 70)" strokeWidth={6} strokeWidthSecondary={6}/>
        <span>Sonuçlar yükleniyor..</span>
      </div>
    );
  };

  return (
    <div className="h-[50px] w-full top-4 flex items-center justify-center z-[9999] fixed">
      <div className="w-full bg-zinc-200/40 px-4 flex items-center h-full rounded-[30px] sm:w-[680px] backdrop-blur-lg">
        <NavLink to="/" className="flex flex-row items-center justify-center w-max cursor-pointer">
          <img className="w-[32px] h-auto" src={Logo} alt="Vocal"></img>
          <span className='hidden sm:block font-bold ml-1 sm:ml-2 select-none md:text-[18px] text-[12px] sm:text-[15px]'>Vocal</span>
        </NavLink>

        <div className='flex items-center justify-center sm:w-full w-full md:w-[52%] h-full relative'>
          <div id="search-input" className="flex w-[90%] bg-zinc-500/10 group cursor-text relative rounded-2xl h-[60%] items-center px-2 text-[14px]">
            <label htmlFor="search">
              <BiSearchAlt2 size={18} className='group-hover:text-zinc-600 text-zinc-500 absolute top-[7px] transition-all'/>
            </label>
            <input autoComplete="off" id="search" onChange={event => setSearch(event.target.value.replace(/ +/g, '').trim())} type="text" spellCheck={false} placeholder='Bir sunucu ara' className='w-[92%] group-hover:text-zinc-600 group-hover:placeholder:text-zinc-600 text-zinc-500 placeholder:text-zinc-500 bg-transparent outline-none border-none relative left-5 transition-all placeholder:transition-all'></input>
          </div>

          <div id="search-modal" className={(search && showModal ? '!opacity-100 !top-12 z-[1]' : '') + `-z-[1] backdrop-blur-lg gap-y-2 opacity-0 linear duration-200 transition-all absolute top-0 flex w-max bg-zinc-200/60 text-black h-max max-h-[350px] flex-col text-center items-center justify-center rounded-lg px-3 py-2 text-[14px]`}>
            {searchResults.length <= 0 && !searching && 'Hiç sonuç bulunamadı.'}
            {searching && <Loading/>}
            {searchResults.length > 0 && searchResults.map((item, index) => (
              <NavLink to={'/servers/' + item.id} key={index} className="flex flex-row items-center justify-start gap-2 w-full h-max cursor-pointer hover:bg-zinc-600/20 hover:text-zinc-600 py-1 px-2 rounded-lg">
                <img className="w-[24px] rounded-full h-auto" src={item.img} alt={`${item.name} sunucusunun profil resmi`} />
                <p className='font-semibold'>{item.name}</p>
              </NavLink>
            ))   }
          </div>
        </div>

        <div className='flex-1 flex items-center justify-end gap-x-2 text-[12px]'>
          <NavLink to="/" className='cursor-pointer font-semibold text-zinc-500 bg-zinc-500/10 hover:bg-zinc-500/30 hover:text-zinc-600 flex items-center justify-center py-1 px-2 md:px-4 rounded-xl'>
            <AiFillHome className='relative md:right-1' size={14}/>
            <span className='hidden md:block'>Anasayfa</span>
          </NavLink>
          <a href="https://discord.vocal.tk" className='cursor-pointer font-semibold text-blue-500 bg-blue-500/10 hover:bg-blue-500/90 hover:text-white flex items-center justify-center py-1 px-2 md:px-4 rounded-xl'>
            <FaDiscord className='relative md:right-1' size={14}/>
            <span className='hidden md:block'>Davet et</span>
          </a>
        </div>
      </div>
    </div>
  );
}