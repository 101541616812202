import Helmet from 'react-helmet';
import HomeComponent from '../Components/Home';
import Footer from '../Components/Footer';
import ServersComponent from '../Components/Servers';

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function () {

  return (
    <div className="w-full h-full min-h-screen bg-zinc-100">
      <Helmet>
        <title>Vocal</title>
      </Helmet>

      <HomeComponent/>
      <ServersComponent/>
      <Footer/>
    </div>
  );
};