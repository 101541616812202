import { useState, useEffect } from 'react';
import Twemoji  from 'react-twemoji';
import { Oval } from  'react-loader-spinner'
import Toast, { Toaster } from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import { FaUser, IoMdThumbsUp, MdKeyboardVoice, IoMdRefresh, IoMdClose } from '../Utils/Icons';
import Tippy from './Tippy'

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function() {

  const [categorys, setCategorys] = useState([]);
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState('voices');
  const [activeCategory, setActiveCategory] = useState(false);

  const sorts = {
    'Ses': 'voices',
    'Üye': 'members',
    'Oy': 'votes',
    'En yeni': 'news'
  };

  function handleSortActive(event) {
    if (activeFilter === sorts[event.target.innerText]) return;
    [...document.getElementById('filter-buttons-sort').querySelectorAll('button')].filter(button => button !== event.target).forEach(button => button.classList.remove('!bg-blue-500/30', '!cursor-default'));
    event.target.classList.add('!bg-blue-500/30', '!cursor-default');

    setActiveFilter(sorts[event.target.innerText]);
    getData(sorts[event.target.innerText], activeCategory);
  };

  function handleCategoryActive(event) {
    if (activeCategory === event.target.innerText) return;
    [...document.getElementById('filter-buttons-category').querySelectorAll('button')].filter(button => button !== event.target).forEach(button => button.classList.remove('!bg-indigo-500/30', '!cursor-default'));
    event.target.classList.add('!bg-indigo-500/30', '!cursor-default');

    setActiveCategory(event.target.innerText);
    getData(activeFilter, event.target.innerText);
  };

  async function getCategorys() {
    if (window.sessionStorage.getItem('categorys')) setCategorys(JSON.parse(window.sessionStorage.getItem('categorys')));
    else {
      const response = await (await fetch('https://api.vocal.tk/categorys')).json();
      if (response) {
        setCategorys(response);
        window.sessionStorage.setItem('categorys', JSON.stringify(response));
      };
    };
  };

  useEffect(() => {
    if (activeCategory == null) {
      [...document.getElementById('filter-buttons-category').querySelectorAll('button')].forEach(button => button.classList.remove('!bg-indigo-500/30', '!cursor-default'));
      getData(activeFilter, null, false);
    };
  }, [activeCategory]);

  useEffect(() => {
    getCategorys();
    getData('voices', null, false);
    document.getElementById('filter-buttons-sort').querySelectorAll('button')[0].classList.add('!bg-blue-500/30', '!cursor-default');
  }, []);

  async function getData(sort, category, categoryCheck = false) {
    if (loading) return Toast.error('Yeni veriler yüklenirken lütfen bekleyin.', { id: 'loading-error', style: { background: 'rgb(228 228 231)' } });
    setLoading(true);
    const response = await fetch(category ? ('https://api.vocal.tk/servers?sort=' + sort + '&category=' + encodeURIComponent(category)) : ('https://api.vocal.tk/servers?sort=' + sort)).catch(() => {});
    if (categorys.length <= 0 && categoryCheck === true) await getCategorys();
    Toast.dismiss('loading-error');
    setLoading(false);
    if (response) {
      const json = await response.json();
      if (json.error) return Toast.error(json.error, { id: 'request-error', style: { background: 'rgb(228 228 231)' } });
      setServers(json);
    } else setServers([]);
  };

  return (
    <div className="h-full px-2 flex flex-row w-full">
      <div><Toaster position='right-bottom'/></div>
      <div className="pt-24 xl:pt-0 w-full h-full flex justify-center items-center relative flex-col" id="servers" style={{ scrollMarginTop: '6rem' }}>
        
        <h1 className='text-gray-800 font-bold text-2xl flex gap-x-3 flex-row items-center'><Twemoji options={{ className: 'w-6' }}>🏆</Twemoji> Sıralama</h1>
        <p className='text-gray-600 font-medium text-md max-w-[550px] text-center mt-3'>Buradan şu anki ses sıralamasını görüntüleyebilirsin.<br/>Eğer istersen butonlar aracılığı ile filtreleme yapabilirsin.</p>

        <div className='flex flex-col items-center justify-center w-full mt-5'>
          <div className='flex gap-2 flex-wrap items-center justify-center flex-row w-full' id="filter-buttons-sort">
            <button onClick={handleSortActive} className='text-blue-500 bg-blue-500/10 text-[14px] px-4 py-1 rounded-2xl select-none transition-all active:scale-90 hover:bg-blue-500/20'>Ses</button>
            <button onClick={handleSortActive} className='text-blue-500 bg-blue-500/10 text-[14px] px-4 py-1 rounded-2xl select-none transition-all active:scale-90 hover:bg-blue-500/20'>Üye</button>
            <button onClick={handleSortActive} className='text-blue-500 bg-blue-500/10 text-[14px] px-4 py-1 rounded-2xl select-none transition-all active:scale-90 hover:bg-blue-500/20'>Oy</button>
            <button onClick={handleSortActive} className='text-blue-500 bg-blue-500/10 text-[14px] px-4 py-1 rounded-2xl select-none transition-all active:scale-90 hover:bg-blue-500/20'>En yeni</button>
            <Tippy title="Yenile">
              <button onClick={() => getData(activeFilter, activeCategory)} className='bg-blue-500 hover:bg-blue-600 text-white text-[16px] p-2 rounded-2xl select-none transition-all active:scale-90'>
                <IoMdRefresh />
              </button>
            </Tippy>
          </div>

          <div className='flex gap-2 flex-wrap items-center justify-center flex-row w-full mt-4' id="filter-buttons-category">
            {categorys.map(category => <button onClick={handleCategoryActive} key={category} className='text-indigo-500 hover:bg-indigo-500/20 bg-indigo-500/10 text-[14px] px-4 py-1 rounded-2xl select-none transition-all active:scale-90'>{category}</button>)}
            <button onClick={() => { if (!loading) setActiveCategory(null) }} className={`${activeCategory ? 'opacity-100 hover:bg-indigo-600 active:scale-90' : 'opacity-[0.5] cursor-default'} transition-all bg-indigo-500 text-white text-[16px] p-2 rounded-2xl select-none`}>
              <IoMdClose />
            </button>
          </div>

          <div className='w-full h-full mt-10 md:min-h-[200px] justify-center flex items-center'>
            {loading ? (
            <div className='gap-2 flex text-[16px] text-gray-500 items-center justify-center'>
              <Oval height={20} width={20} color="rgb(113 113 122)" visible={true} ariaLabel='oval-loading' secondaryColor="rgb(63 63 70)" strokeWidth={6} strokeWidthSecondary={6}/>
              <span>Yükleniyor.</span>
            </div>
            ) : ''}

            {!loading && servers.length > 0 ? (
              <div className='w-full h-full grid xl:gap-6 xl:grid-cols-3 xl:max-w-[1280px] lg:grid-cols-2 lg:max-w-[840px] gap-y-4 md:grid-cols-1 md:max-w-[400px] mb-10'>
                {servers.map((item, index) => (
                  <div key={item.id} className='bg-zinc-200 w-full sm:w-[400px] h-[150px] rounded-xl flex flex-col relative'>
                    <div className='select-none drop-shadow-lg absolute top-2 left-2 rounded-full backdrop-blur-lg w-[26px] h-[26px] text-[14px] font-bold flex items-center justify-center text-white'>
                      {(index + 1).toLocaleString('en-US')}
                    </div>

                    <div className='select-none drop-shadow-lg absolute top-2 right-2 rounded-full backdrop-blur-xl w-max px-3 py-1 h-max text-[14px] font-bold flex items-center justify-center text-white'>
                      {item.category}
                    </div>
                    {item.banner ? <img className="h-[50px] object-cover w-full rounded-x-0 rounded-t-xl" src={item.banner} alt={`${item.name} sunucusunun afişi`}></img> : <div className="h-[50px] w-full rounded-x-0 rounded-t-xl bg-zinc-300/40"></div>}
                    <div className='flex-1 w-full py-4 px-4 flex gap-x-2 items-center'>
                      <img className="w-[32px] sm:w-[64px] h-auto rounded-full" src={item.img} alt={`${item.name} sunucusunun ikonu`}></img>
                      <div className='flex flex-col h-full w-max justify-center'>
                        <span className={`font-bold text-gray-900 text-[14px] sm:text-[16px] truncate w-[130px] sm:w-[175px] tracking-wide flex flex-row items-center gap-x-[5px]`}>
                          <span className="truncate w-max">{item.name}</span>
                          {item.badges?.map((data, index) => (
                            <div key={index} className='w-[16px] h-[16px] min-w-fit'>
                              <Tippy title={data.name}>
                                <img className="w-full h-full object-cover" src={data.img} alt={data.name}></img>
                              </Tippy>
                            </div>
                          ))}
                        </span>
                        <div className='text-[12px] font-medium gap-2 flex flex-row items-center'>
                          <div className='flex flex-row items-center gap-1'>
                            <span className="w-[6px] h-[6px] bg-green-600 rounded-full">&thinsp;</span>
                            <span className="select-none">{item.onlines}</span>
                          </div>
                          {activeFilter === 'voices' && (
                            <div className='flex flex-row items-center gap-1'>
                              <MdKeyboardVoice size={12}/>
                              <span className="select-none">{item.voices || 0}</span>
                            </div>
                          )}
                          {activeFilter === 'members' && (
                            <div className='flex flex-row items-center gap-1'>
                              <FaUser className="text-zinc-600" size={8}/>
                              <span className="select-none">{item.members || 0}</span>
                            </div>
                          )}
                          {activeFilter === 'votes' && (
                            <div className='flex flex-row items-center gap-1'>
                              <IoMdThumbsUp size={12}/>
                              <span className="select-none">{item.votes || 0}</span>
                            </div>
                          )}
                        </div>
                      </div>
  
                      <div className="flex-1 flex justify-end items-center">
                        <NavLink to={'/servers/' + item.id} className="bg-green-500 text-white white rounded-2xl text-[10px] md:text-[14px] py-1 px-4 font-bold hover:bg-green-600 select-none">Görüntüle</NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (!loading ? (
                <div class="w-full h-full flex-col gap-1 flex items-center justify-center">
                  <h1 class="text-black font-bold text-sm flex flex-row gap-x-2">Üzgünüm <Twemoji options={{ className: 'w-4' }}>😢</Twemoji></h1>
                  <p class="text-gray-600 text-xs">Hiç sunucu bulunamadı.</p>
                </div>
            ) : '') }
          </div>
        </div>

      </div>
    </div>
  );
};